.hero {
	position: relative;
	box-sizing: border-box !important;

	&__color {
		&--brand {
			background: $color-brand-primary;
		}
	}

	&__size {
		&--large {
			height: 100vh;
		}
	}

		.hero__skills {
			z-index: 0;
			text-align: center;
			position: absolute;
			// top: 50%;
    	// transform: translateY(-50%);
			width: 100%;

			h2 {
				font-family: $display-font-stack;
				@include fontSize(60px);
				line-height: rem(66px);
				margin: 0;
				padding: 35% 0 0 0;

				@include susy-media($medium) {
					@include fontSize(120px);
					line-height: rem(150px);
					padding: 18% 0 0 0;
				}

				@include susy-media($large) {
					@include fontSize(120px);
					line-height: rem(150px);
					padding: 0 0 0 0;
				}

				b {
					opacity: 0;
	    		position: absolute;
	    		left: 50%;
					transform: translateX(-50%);

					&.is-visible {
						opacity: .3;
					}

					&.is-hidden {
						opacity: 0;
					}
				}
			}
		}

		.hero__heading {
			z-index: 1;
			text-align: center;
			position: absolute;
			top: 50%;
    	transform: translateY(-50%);

			padding: 0 5%;
			@include susy-media($medium) {
				padding: 0 7.5%;
			}

			@include susy-media($xxxlarge) {
				padding: 0 15%;
			}
		}

		.hero__scroll {
			position: absolute;
			width: 100%;
			bottom: 10%;
		  @include susy-media($medium) {
		    bottom: 5%;
		  }
			text-align: center;
			@include fontSize(21px);
			color: $color-brand-secondary;

			&.bounce {
			  -moz-animation: bounce 2s infinite;
			  -webkit-animation: bounce 2s infinite;
			  animation: bounce 2s infinite;
			}

			@keyframes bounce {
			  0%, 20%, 50%, 80%, 100% {
			    transform: translateY(0);
			  }
			  40% {
			    transform: translateY(-30px);
			  }
			  60% {
			    transform: translateY(-15px);
			  }
			}
		}
}

.project__hero {
	width: 100%;

	&.image-wrap {
		@include aspect-ratio(4,3);

		@include susy-media($medium) {
			@include aspect-ratio(3,2);
		}

		@include susy-media($large) {
			@include aspect-ratio(2,1);
		}
		@include susy-media($xlarge) {
			@include aspect-ratio(2,1);
		}
	}
}
