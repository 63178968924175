input[type=text],
input[type=email],
input[type=date],
input[type=url],
input[type=password],
input[type=number] {
	height: rem(60px);
	line-height: rem(60px);
	border: 2px solid $color-white;
	padding: 0 rem(8px);
	border-radius: rem(15px);
	border-top-right-radius: 0;
	@include fontSize(15px);

	&:focus {
		outline: none;
	}

	&.error {
		background-color: lighten($color-invalid, 55%);
	}

}
