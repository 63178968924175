/**
 * Basic typography style for copy text
 */
html {
	font-size: 100%;
}
body {
  color: $color-txt-body;
  font: normal 100% / 1.6 $text-font-stack;
  font-weight: 300;
	-webkit-font-smoothing: antialiased;
}


/**
 * Basic typography style for headers
 */

h1, h2, h3, h4, h5, h6 {
	color: $color-txt-headings;
	font-family: $header-font-stack;
	letter-spacing: 0;
	line-height: 0.8;
	font-weight: normal;
}

h1 {
	color: $color-white;
	font-size: 36px; /* Some tweener fallback that doesn't look awful */
  font-size: 5.4vh;
	line-height: 6.2vh;

	@include susy-media($medium) {
		@include fontSize(60px);
		line-height: rem(72px);
	}

}

h2, .tile-title, .footer-title {
	@include fontSize(42px);
	line-height: rem(48px);
	color: $color-brand-secondary;
}

.tile-title {
	@include fontSize(24px);
	line-height: rem(30px);

	@include susy-media($medium) {
		@include fontSize(36px);
		line-height: rem(42px);
	}
	@include susy-media($large) {
		@include fontSize(42px);
		line-height: rem(48px);
	}
}

h3, .work-desc {
	@include fontSize(30px);
	line-height: rem(42px);
	color: $color-brand-primary;
}

.work-desc {
	color: $color-white;
	@include fontSize(18px);
	line-height: rem(24px);

	@include susy-media($medium) {
		@include fontSize(24px);
		line-height: rem(30px);
	}
	@include susy-media($large) {
		@include fontSize(30px);
		line-height: rem(42px);
	}
}

h5, .small-header {
	@include fontSize(15px);
	line-height: rem(24px);
	font-family: $text-font-stack;
	text-transform: uppercase;
	letter-spacing: $letter-spacing-default;
	color: $color-brand-secondary;
}

li.small-header {
	margin-bottom: rem(6px);
}

.tile-desc, .footer-copy {
	@include fontSize(24px);
	line-height: rem(30px);
	margin: rem(21px) 0;
	color: $color-white;
}

p {
	@include fontSize(18px);
	line-height: rem(24px);

	&.large {
		@include fontSize(24px);
		line-height: rem(36px);
	}

	@include susy-media($medium) {
		@include fontSize(21px);
		line-height: rem(30px);
	}
}
