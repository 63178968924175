// -----------------------------------------------------------------------------
// This file contains all color-based application-wide Sass variables.
// -----------------------------------------------------------------------------

// Colors
$color-dark-blue: #0c4d99;
$color-soft-cyan: #4fe3e6;

$color-blue-grey: #616680;
$color-off-white: #F8F8F8;
$color-white: #ffffff;

$color-red: red;
$color-black: black;

$red: red;
$black: black;

// Brand colours
$color-brand-primary: $color-dark-blue;
$color-brand-secondary: $color-soft-cyan;

/// Button colors
$color-btn-text: $color-brand-secondary;

// Copy and heading color
$color-txt-body: $color-blue-grey;
$color-txt-headings: $color-brand-secondary;

/// Highlighting color
$color-highlight: $color-off-white;
$color-highlight-text: $color-txt-body;

// UI colors
$color-ui-primary: $color-brand-primary;
$color-ui-secondary: $color-brand-secondary;

$color-ui-bg: $color-white; /* Background color */

$color-ui-header-bg: $color-brand-primary; /* Header */

$color-ui-footer-bg: $color-brand-secondary; /* Footer */
$color-ui-footer-txt: $color-white; /* Footer text color */

// Links
$color-link:        $color-brand-secondary;
$color-link-reverse: $color-white;
$color-link-hover:  ;

//Validation
$color-valid: green;
$color-invalid:  red;
