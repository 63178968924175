.details__table {
  margin-top: rem(12px);
  @include susy-media($medium) {
    margin-top: rem(36px);
  }

  .row {
    @extend .clearfix;
    margin-bottom: rem(12px);

    @include susy-media($medium) {
      margin-bottom: rem(6px);
    }

    @include susy-media($large) {
      margin-bottom: rem(12px);
    }

    @include susy-media($xlarge) {
      margin-bottom: rem(6px);
    }

    .th {
      @include susy-media($small) {
        @include span(4);
      }
      @include susy-media($medium) {
        @include span(2);
      }
      @include susy-media($large) {
        @include span(12);
      }
      @include susy-media($xlarge) {
        @include span(3);
      }
    }
    .td {

      @include susy-media($small) {
        @include span(8 last);
      }
      @include susy-media($medium) {
        @include span(10 last);
      }
      @include susy-media($large) {
        @include span(12);
      }
      @include susy-media($xlarge) {
        @include span(9 last);
      }
      p {
        margin: 0;
        padding: 0;
      }
    }
  }
}


// @include susy-media($large) {
//   @include span(8);
// }
