// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

footer {
	background-color: $color-ui-footer-bg;
	color: $color-ui-footer-txt;
	z-index: 2;
  position: relative;

	a {
		color: $color-ui-footer-txt;
	}

	.site-info {
		font-family: $header-font-stack;
		@include susy-media($large) {
			 @include span(8);
		}

		.footer-title {
			color: $color-brand-primary;
			display: block;
			margin-bottom: rem(12px);
		}

		.footer-copy {
			line-height: rem(33px);
			a {
				display: inline-block;
				&:after {
					content: '';
					display: block;
					margin: auto;
					height: rem(1px);
					width: 0;
					background: transparent;
					transition: width 0.5s ease, background-color 0.5s ease;
				}

				@include on-event {
					&:after {
						width: 100%;
						background: $color-white;
						opacity: 0.8;
					}
				}
				&:hover {
					padding: 0;
					background: transparent;
				}
			}
		}
	}

	.site-contact {
		@include susy-media($large) {
			 @include span(8);
		}

		ul {
			margin: rem(30px) 0;

			li {
				display: inline-block;
				margin-right: rem(36px);

				a {
					@include fontSize(24px);
					color: $color-ui-footer-txt;
					padding: 0;

					&:hover {
						padding: 0;
						opacity: 0.65;
						transition: opacity 0.2s 0.1s ease;
					}
				}
			}
		}
	}

	.site-year {
		@include susy-media($large) {
			 @include span(8);
		}
	}
}
