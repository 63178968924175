// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased
;

	&.menu-open {
		height: 100%;

		body {
			height: 100%;
			overflow: hidden;
		}
	}

}

body {
	background-color: $color-brand-primary;
}

main {
	background-color: $color-ui-bg;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*, *::before, *::after {
	box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
	color: $color-link;
	text-decoration: none;
	transition: color 0.15s;
	border-bottom: 1px solid $color-link;
	padding: rem(2px);

	@include on-event {
		color: $color-white;
		background: $color-link;
	}
}

/**
 * Basic styles for blockquotes
 */
blockquote {
	border-left: 9px solid $color-brand-secondary;
	margin: rem(60px) 0 rem(90px) 0;
	font-family: $header-font-stack;
	@include susy-media($large) {
			@include push(1);
			@include span(10);
	}

	p {
		@include fontSize(36px);
		line-height: rem(42px);
		color: $color-brand-primary;
		margin: 0 0 rem(18px) rem(42px);
		padding: 0;

		@include susy-media($large) {
			@include fontSize(42px);
			line-height: rem(54px);
		}
	}

	footer {
		@include fontSize(24px);
		line-height: rem(30px);
		color: $color-blue-grey;
		background: transparent;
		margin: 0 0 0 rem(42px);

		@include susy-media($large) {
			@include fontSize(30px);
			line-height: rem(42px);
		}
	}
}

/**
 * Text Highlight color
 */
::-moz-selection {
	background: darken($color-highlight, 5%);
	color: darken($color-highlight-text, 0%);
}

::selection {
	background: darken($color-highlight, 5%);
	color: darken($color-highlight-text, 0%);
}

/* Strike through colour */
del {
	color: $color-brand-primary;
}
