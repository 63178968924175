.tile {
	position: relative;
	display: block;
	color: $color-txt-body;
	overflow: hidden;
	background: $color-brand-primary !important;
	border-bottom: none;
	padding: 0;

	.image-wrap {
		transition: opacity 0.2s;
		line-height: 0;
		@include aspect-ratio(4,3);

		@include susy-media($medium) {
			@include aspect-ratio(5,4);
		}
		@include susy-media($large) {
			@include aspect-ratio(3,2);
		}
	}

	&__content {

		.tile__reveal {
		  opacity: 0;
			position: absolute;
			top: 50%;
	    -webkit-transform: translateY(-50%);
	    transform: translateY(-50%);
			position: absolute;
	    width: 100%;
	    text-align: center;
	    padding: 0 10%;
	    z-index: 2;

			.tile-title, .tile-desc {
				display: block;
				font-family: $header-font-stack;
			}
		}
	}

	&:hover {
		padding: 0;
		cursor: pointer;

		.tile__content {

			.tile__reveal {
				opacity: 1;
		    transition: opacity 0.2s 0.1s ease;
			}
		}

		.image-wrap {
			opacity: 0.3;
		}
	}
}
