// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
header.site-header {
	margin: 0 auto;
	padding: rem(30px) gutter();
	transition: background-color 0s;
	position: relative;
	z-index: 5;
	text-align: center;
	background: $color-ui-header-bg;

	@include susy-media($medium) {
		/* &.sticky {
			padding: rem(20px) 0;
		} */
	}

	&.has-hero {
    	margin-bottom: -127px;
		width: 100%;
		z-index: 20;
		background: transparent;
		//background: linear-gradient(to bottom, rgba(0,0,0,0.45) 0%,rgba(0,0,0,0) 90%);

		&:after {
			display: none;
		}
	}

}
