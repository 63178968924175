.pagination {
  .previous {
    @include span(6);
    padding-left: rem(15px);
    text-align: left;
    display: none;

    @include susy-media($small) {
      display: block;
    }
  }
  .next {
    @include span(12);
    padding-right: rem(15px);
    text-align: right;

    @include susy-media($small) {
      @include span(6 last);
    }
  }
}
