.bio {
	&--intro {
		@include span(12);

		@include susy-media($large) {
			@include span(9);
		}
	}

	&--skills {
		@include span(12);

		@include susy-media($large) {
			@include span(4.5 last);
		}


		ul {
			@include span(6 of 12);

			&:last-child {
				@include span(6 of 12 last);
			}

			@include susy-media($large) {
				margin-top: rem(42px);
				&:last-child {
					margin-top: rem(66px);
				}
			}
		}
	}
}

.work {
	&--intro {
		@include susy-media($large) {
			@include span(8);
		}
	}
	&--details {
		@include susy-media($large) {
			@include span(4 last);
		}
	}
	&--info {
		margin-bottom: rem(30px) !important;

		@include susy-media($large) {
			@include span(10);
			h3 {
				padding-top: 0 !important;
				margin-top: 0 !important;
			}
			margin-bottom: rem(60px) !important;
		}
	}
}

.editorial {

	&--capabilities {
		h5 {
			@include span(12);
			margin-top: rem(22px);

			@include susy-media($large) {
				@include span(6);
			}
		}


	}

	&--intro {
		.half {
			@include susy-media($medium) {
				@include span(8 of 12 0);
				margin-right: 0;

				&:nth-of-type(2n) {
					@include span(4 of 12 0 last);
				}

			}

			@include susy-media($large) {
				@include span(6 of 12 0);
				margin-right: 0;

				&:nth-of-type(2n) {
					@include span(6 of 12 0 last);
				}

			}
			&.asset {
				height: 50vh;
			}

		}
	}

	&--details {
		.half {
				@include span(6);

				&:nth-of-type(2n) {
					@include span(6 last);
				}
		}
	}
}

//Bog standard grid

.half {
	@include susy-media($medium) {
		@include span(6);

		&:nth-of-type(2n) {
			@include span(6 last);
		}
	}
}
