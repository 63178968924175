.hamburger {
	position: fixed;
	transition: transform 0.3s ease-in-out, color 0.3s ease-in-out, margin 0.3s ease-in-out;
	background: transparent;
	text-indent: -9999px;
	overflow: hidden;
	padding: rem(20px);
	top: rem(30px);
	right: 0;
	transform: translateY(-50%);
	z-index: 22;

	.admin-bar & {
		top: rem(76px);
	}

	.line {
		width: rem(25px);
		height: rem(2px);
		background-color: $color-white;
		display: block;
		margin: 5px auto;
		transition: all 0.15s ease-in-out;
	}

	@include susy-media($large) {
		top: 50%;
		left: gutter() / 2;
		right: auto;


	}



	@include on-event {
		cursor: pointer;
		background: transparent;
		padding: rem(20px);
	}

	&:hover {
		.line {
			width: rem(30px);
		}
	}

	&.open {
		transform: translateY(rem(-30px)) rotate(45deg);

		.line{
			width: 25px;
		}

		.line:nth-child(1){
			transform: translateY(7px);
		}

		.line:nth-child(2){
			opacity: 0;
		}

		.line:nth-child(3){
			transform: translateY(-7px) rotate(90deg);
		}
	}

	.has-hero &,
	.menu-open & {
		.line {
			background-color: $color-white;
		}
	}

	.sticky & {
		transition: transform 0.3s ease-in-out;
		margin-top: 0;

		@include susy-media($medium) {
			margin-top: 0;
		}

		.line {
			background-color: $color-white;
		}
	}

}
