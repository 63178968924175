.image-wrap {
	position: relative;
}

.image-fill {
	@include object-fit(cover);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.image-fit {
	@include object-fit(contain);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}