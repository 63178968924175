// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@font-face {
    font-family: 'Europa Bold';
    src: url('../../fonts/europabold-webfont.woff2') format('woff2'),
         url('../../fonts/europabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Europa Bold Italic';
    src: url('../../fonts/europabolditalic-webfont.woff2') format('woff2'),
         url('../../fonts/europabolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'Europa Light';
    src: url('../../fonts/europalight-webfont.woff2') format('woff2'),
         url('../../fonts/europalight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'Europa Light Italic';
    src: url('../../fonts/europalightitalic-webfont.woff2') format('woff2'),
         url('../../fonts/europalightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'Europa Regular';
    src: url('../../fonts/europaregular-webfont.woff2') format('woff2'),
         url('../../fonts/europaregular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'Europa Regular Italic';
    src: url('../../fonts/europaregularitalic-webfont.woff2') format('woff2'),
         url('../../fonts/europaregularitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
