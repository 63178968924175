// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix {
	@include clearfix;
}


/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
	overflow: hidden;
	padding: 0; /* 1 */
	text-indent: 101%;
	white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.center-text {
	text-align: center;
}

.left-text {
	text-align: left;
}

.right-text {
	text-align: right;
}

.color-primary {
	color: $color-brand-primary
}

.color-secondary {
	color: $color-brand-primary
}

hr.spacer {
	border: none;
	margin-bottom: 0.5em;
}

.table {
	height:100%;
	width: 100%;
	display: table
}

.table-cell {
	display: table-cell;
	vertical-align: middle;
}

.extra-margin {
	margin: gutter() * 2 0 gutter() 0;
}


/*--------------------------------------------------------------
# Background Colour Shortcuts
--------------------------------------------------------------*/

.bg {
	&--primary {
		background-color: $color-brand-primary;
		color: $color-white;
	}
	&--secondary {
		background-color: $color-brand-secondary;
		color: $color-white;
	}
	&--off-white {
		background-color: $color-off-white;
	}
}


/*--------------------------------------------------------------
# Text colour
--------------------------------------------------------------*/

.text {
	&--white {
		color: $color-white;
	}
}


/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers. */
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000; /* Above WP toolbar. */
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
	outline: 0;
}
