.modal-mask {
	position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.8);
    z-index: 99999;
    display: none;

    .modal {
    	position: absolute;
	    min-width: 60%;
	    left: 50%;
	    top: 50%;
	    transform: translateY(-50%);
	    margin-left: -30%;
	    background: #2b2b2b;
    	box-shadow: 0 0 6em rgba(0,0,0,1);

    	.close-modal {
			display: block;
			position: absolute;
			top: rem(-40px);
			right: 0;
			color: white;
			background: black;
			height: rem(40px);
		    width: rem(40px);
		    line-height: 1.5;
		    text-align: center;
		    @include fontSize(28px);
		    cursor: pointer;
            text-decoration: none;
            transition: background-color 0.1s ease-out, color 0.1s ease-out;

            @include on-event {
                background: white;
                color: black;
            }

            @include susy-media($large) {
                top: 0;
                right: rem(-40px);
            }
    	}
        
        .video-wrapper {
            position: relative;
            padding-bottom: 56.25%; /* 16:9 */
            height: 0;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    	
    }

    &.full-screen {
        background: rgba(0,0,0,1);

        .modal {
            width: 90vw;
            left: 5vw;
            margin-left: 0;
        }
    }
}