// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.button, button {
	@include fontSize(15px);
	color: $color-btn-text;
	text-transform: uppercase;
	font-weight: 300;
	cursor: pointer;
	letter-spacing: $letter-spacing-links;
	border: none;
	border-radius: 0;
	display: inline-block;

	&:after {
		content: '';
		display: block;
		margin: auto;
		height: rem(1px);
		width: 0;
		background: transparent;
		transition: width 0.5s ease, background-color 0.5s ease;
	}
	
	@include on-event {
		&:after {
			width: 100%;
			background: $color-btn-text;;
			opacity: 0.8;
		}
	}
}
