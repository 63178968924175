.scroll-pin-wrapper {
	position: relative;
}

.scroll-pin-content {
	position: absolute;
	width: 100%;
	height: 100vh;

	&.pinned {
		position: fixed;
		width: 50% - gutter() / 2;
		top: 0;
		left: 50% + gutter() / 2;

		&.pinned-end {
			position: absolute;
			bottom: 0;
			width: 100%;
			top: auto;
			left: auto;
		}
	}
}
