.gallery {
  margin-top: rem(90px);
  &__item {
  margin-bottom: gutter();

  &.half {
    @include susy-media($medium) {
  		@include span(6);
  	}

    &.last {
      @include susy-media($medium) {
    		@include span(6 last);
    	}
    }

  }

  &.third {
  	@include susy-media($medium) {
  		@include span(4);

  		&.last {
  			@include span(4 last);
  		}
  	}

    .image-wrap {
      @include aspect-ratio(1,1.06);
    }

  }

  &.two-thirds {
  	@include susy-media($medium) {
  		@include span(8);

  		&.last {
  			@include span(8 last);
  		}
  	}

    .image-wrap {
      @include aspect-ratio(2,1);
    }
  }
  }
}
