.block {
	@include container;
	padding: rem(54px) 7.5%;

	@include susy-media($large) {
		padding: rem(84px) 7.5%;
	}

	&--nopad {
		padding: 0;
	}

	&--toppad {
		padding-top: rem(30px);

		@include susy-media($medium) {
			padding-top: rem(60px);
		}

		@include susy-media($large) {
			padding-top: rem(90px);
		}
	}
}

.container {
	@extend .clearfix;
	margin: 0 auto;

	@include susy-media($xxxlarge) {
		max-width: $xxlarge;
	}
}
