.hero__logo {
  text-align: center;
  padding: 10% 0;
  @include susy-media($medium) {
    padding: 5% 0;
  }
  a.logo {
    img {
      width: 80px;
      @include susy-media($medium) {
        width: inherit;
      }
    }

    cursor: pointer;
    border: none;
    &:hover {
      background: transparent;
      opacity: .65;
      transition: opacity 0.2s 0.1s ease;
      padding: 0px;
    }
  }
}
