ul, ol {
	margin: 0 0 1em 0;
	padding:0;

	li {
		list-style: none;
		ul, ol {
			padding-left: 0;
			list-style: none;
		}
	}

	&.work-list {
		list-style: none;
		padding-left: 0;
		margin: 0;
		@extend .clearfix;

		li {

			@include span(12 of 12);

			&:first-child {
				display: none;
			}

			@include susy-media($small) {
				@include span(6 of 12 0);

				&:nth-of-type(2n) {
					@include span(6 of 12 0 last);
				}

				&:first-child {
					display: block;
				}
			}

			@include susy-media($xxlarge) {
				@include span(6 of 12 0);

				&:nth-of-type(2n) {
					@include span(6 of 12 0 last);
				}

				// &:nth-of-type(3n) {
				// 	@include span(4 of 12 0 last);
				// }
			}

			> a {
				display: block;
				color: inherit;
				// transition: text-indent 0.2s $ease-in-out-circ;

				&:hover {
					// text-indent: rem(10px);
				}
			}

		}
	}
}

.work--details {
	ul {
		margin-top: rem(42px);
		li {
			.small-header {
				@include span(3 of 12 0);
			}
			.small-text {
				@include span(9 of 12 0 last);
			}
		}
	}
}
